
.boxHeader {
  width: 100%;
  margin-top: 0px;
  height: 45px;
  display: flex;
  justify-content: center;
}

.boxStart {
  margin-bottom: 2.5rem;
}

.typographyCart {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.typographyCartPrice {
  font-size: 1rem;
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.gridCart {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

@media (min-width: 600px) {
  .boxHeader {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);
    justify-content: start;
  }

  .boxStart {
    margin-bottom: 0.1rem;
  }
}