@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open sans", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
  max-width: 90vw;
}

.login-menu {
  align-items: center;
}

.login-menu ul {
  list-style-type: none;
  float: right;
  align-items: center;
}

.login-menu ul li {
  float: left;
  margin-right: 40px;
  font-family: "Poppins";
  font-size: 1.2rem;
  font-weight: 900;
}

.login-menu ul li a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.login-menu ul li a:hover {
  color: #e72064;
}

.login-menu-mobile a {
  color: rgb(65, 62, 62);
  text-decoration: none;
}

.logo-cc {
  width: 150px;
}

@media only screen 
and (max-device-width: 600px) {
  .logo-cc {
    width: 130px;
    margin-top: 5px;
  }
}

.App {
  text-align: left;
}
@media (max-width: 600px) {
  .App {
    text-align: center;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

    .svg-cc {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 150px;
    }

    .square-cc {
    stroke-dasharray:50;
    stroke-dashoffset:-50;
    -webkit-animation: draw-square-cc 2s infinite;
            animation: draw-square-cc 2s infinite;
    }

    @-webkit-keyframes draw-square-cc {
    from {
        stroke-dashoffset: -100;
    }
    to {
        stroke-dashoffset: 100;
    }
    }

    @keyframes draw-square-cc {
    from {
        stroke-dashoffset: -100;
    }
    to {
        stroke-dashoffset: 100;
    }
    }

.boxHeader {
  width: 100%;
  margin-top: 0px;
  height: 45px;
  display: flex;
  justify-content: center;
}

.boxStart {
  margin-bottom: 2.5rem;
}

.typographyCart {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.typographyCartPrice {
  font-size: 1rem;
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.gridCart {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

@media (min-width: 600px) {
  .boxHeader {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);
    justify-content: start;
  }

  .boxStart {
    margin-bottom: 0.1rem;
  }
}
